import { IPatientTarget } from "../../../@types/patient";
import { Module } from "vuex";

import { ITargetModuleState, IRootState } from "../../../@types/store";
import {
  createPatientTarget,
  deletePatientTarget,
  editPatientTarget,
  getPatientTargets,
  undoTargetDelete,
} from "@/services/patient.service";
import { sortTargetsByType } from "@/factories/patient-factory";
import logger from "@/logger";
import router from "@/router/routes";

const initialState: ITargetModuleState = {
  patientTargetMap: {},
};

const module: Module<ITargetModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getTargets(
      { commit },
      { orgId, patientId }: { orgId: string; patientId: string },
    ) {
      try {
        const targets = (await getPatientTargets(orgId, patientId)).data;
        commit("setPatientTargets", {
          targets: sortTargetsByType(targets),
          patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async addNewTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        target,
      }: {
        orgId: string;
        patientId: string;
        target: IPatientTarget;
      },
    ) {
      try {
        commit("dataPending");
        const newTarget = await createPatientTarget(orgId, patientId, target);
        commit("selectTarget", newTarget.data);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },

    async editTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        target,
      }: {
        orgId: string;
        patientId: string;
        target: IPatientTarget;
      },
    ) {
      try {
        commit("dataPending");
        const newTarget = await editPatientTarget(orgId, patientId, target);
        commit("selectTarget", newTarget.data);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },

    async deleteTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        targetId,
      }: {
        orgId: string;
        patientId: string;
        targetId: string;
      },
    ) {
      try {
        commit("dataPending");
        await deletePatientTarget(orgId, patientId, targetId);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
        throw error;
      } finally {
        commit("dataReceived");
      }
    },

    async undoTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        targetId,
      }: {
        orgId: string;
        patientId: string;
        targetId: string;
      },
    ) {
      try {
        commit("dataPending");
        await undoTargetDelete(orgId, patientId, targetId);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },
  },
  getters: {
    patientTargetMap: (state) => {
      return state.patientTargetMap || {};
    },
    patientTargets: (state) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientTargetMap?.[patientId] || [];
    },
  },
  mutations: {
    setPatientTargets(
      state,
      data: { targets: IPatientTarget[]; patientId: string },
    ) {
      state.patientTargetMap[data.patientId] = data.targets;
      state.patientTargetMap = { ...state.patientTargetMap };
    },
    resetTargetModule(state) {
      state.patientTargetMap = {};
    },
  },
};

export default module;
