import { listPageOrderedReadings } from "@/configs/constants";
import logger from "@/logger";
import { getCampaignTemplates } from "@/services/campaign.service";
import {
  getGlobalSettings,
  getPatientTimelineTemplates,
  getResourceTypes,
  getSystemChecklists,
} from "@/services/organization.service";
import { getAllPatientTypes } from "@/services/patient.service";
import { getAllRoles } from "@/services/roles.service";
import { IChecklist, IPatientType } from "../../../@types/patient";
import { ProviderRole } from "generated/types-gql";
import { Module } from "vuex";

import { CampaignTemplateList } from "../../../@types/campaign";
import { IGlobalSettingEntry, UserLocation } from "../../../@types/globals";
import { ResourceType } from "../../../@types/resource";
import {
  IGlobalSettingsModuleState,
  IRootState,
  PatientListsSchedulesPopoverPayload,
} from "../../../@types/store";
import {
  IPatientTimelineEventTemplate,
  IPatientTimelineTaskTemplate,
} from "../../../@types/patient-timeline-templates";

const initialState: IGlobalSettingsModuleState = {
  allowCrossOrganizationCalls: false,
  checkTabletAvailabilityBeforeVideoCall: false,
  campaignNotificationIndicatorEnabled: false,
  campaignTemplatesList: [],
  diagnoses: [],
  ehrEnableUpdates: false,
  patientTypes: [],
  patientWatchlistsEnabled: false,
  patientListsSchedulesPopover: null,
  providerRoles: [],
  resourceTypes: [],
  userLocations: null,
  patientEventTemplates: [],
  patientTaskTemplates: [],
  checklistTemplates: [],
  showHtpErrorToaster: false,
  showHtpErrorToasterInVideoCallPage: false,
  showRiskDataInDetailsPage: false,
  showMessageReadReceipts: false,
  enableReadRequiredSwitch: false,
  riskDataTimeRange: null,
  showRiskScoreTrend: false,
  bodytraceTruncation: false,
  showAdmissionsAsOngoing: false,
  showReadRequiredFeature: false,
  showTemperatureInCelsius: false,
  showDuplicateNameIndicator: false,
  hideTroubleshootingTab: false,
  isPatientFilteringActive: false,
  showDeviceState: false,
  paginationFeatures: null,
  chartConfigs: null,
  alertFeedbackConfigs: null,
  listPageConfigs: null,
  smsConfigs: null,
  techSupportConfigs: null,
  riskScoreConfigs: [],
  showPoolRegion: false,
  hospitalName: "",
  enableVideoFeedback: false,
  videoFeedbackOptions: [],
  poorVideoFeedbackOptions: [],
  systemPhoto: "",
  providerDefaultPhoto: "",
};

const module: Module<IGlobalSettingsModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getOrgSettingsAndConfigs({ dispatch, commit, rootGetters }) {
      const { data } = await getGlobalSettings(
        rootGetters.rootOrganization.id,
        [
          "patientWatchlistsEnabled",
          "orgPhoto",
          "providerPhoto",
          "SETTINGS___CAMPAIGN_FEATURES",
          "SETTINGS___EHR_CONFIG",
          "SETTINGS___PROVIDER_PORTAL_CHART_CONFIGS",
          "SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS",
          "SETTINGS___PROVIDER_PORTAL_LIST_PAGE_CONFIGS",
          "SETTINGS___RESOURCE_FEATURES",
          "SETTINGS___RISK_SCORE_CONFIGS",
          "SETTINGS___PATIENTS",
          "SETTINGS___FEEDBACK_FEATURES",
          "SETTINGS___PAGINATION_FEATURES",
          "SETTINGS___COMMUNICATIONS_CONFIGS",
          "SETTINGS___PROVIDER_PORTAL_TECH_SUPPORT_CONFIGS",
          "SETTINGS___VIDEO_QUALITY_FEEDBACK_CONFIGS",
          "SETTINGS___THRESHOLD_FEATURES",
        ],
      );
      const globals: Record<string, IGlobalSettingEntry | undefined> = {};
      for (const c of data ?? []) {
        globals[c.id] = c;
      }
      commit("setGlobals", globals);
      if (!Object.values(rootGetters?.allRolesMap)?.length) {
        dispatch("getAllProviderRoles");
      }
      if (!Object.values(rootGetters?.allPatientTypesMap)?.length) {
        dispatch("getAllPatientTypes");
      }
    },

    async getSystemResourceTypes({ commit, rootGetters }) {
      try {
        const data = await getResourceTypes(rootGetters.rootOrganization.id);
        commit("setResourceTypes", data.data);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getAllProviderRoles({ commit, rootGetters }) {
      try {
        const projections = ["id", "color", "name"];
        const data = await getAllRoles(
          true,
          rootGetters.organization.id,
          projections,
        );
        commit("setProviderRoles", data.data);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getAllPatientTypes({ commit, rootGetters }) {
      try {
        const projections = ["id", "name", "displayName"];
        const data = await getAllPatientTypes(
          rootGetters.organization.id,
          projections,
        );
        commit("setPatientTypes", data.data);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getAllPatientTypesRules({ commit, rootGetters }) {
      try {
        const projections = ["id", "rules"];
        const data = await getAllPatientTypes(
          rootGetters.organization.id,
          projections,
        );
        commit("updatePatientTypes", data.data);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getUserLocations({ commit, rootGetters }) {
      try {
        const { data = [] } = await getGlobalSettings(
          rootGetters.organization.id,
          ["userLocations"],
        );
        const [userLocationsSetting] = data;
        commit("setUserLocations", userLocationsSetting?.value || []);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getCampaignTemplatesList({ commit, rootGetters }) {
      try {
        const data = await getCampaignTemplates(
          rootGetters.organization.id,
          true,
        );
        commit("setCampaignTemplatesList", data.data);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientTimelineTemplates({ commit, rootGetters }) {
      try {
        const { data } = await getPatientTimelineTemplates(
          rootGetters.organization.id,
        );
        commit("setPatientTaskTemplates", data.patientTimelineTaskTemplates);
        commit("setPatientEventTemplates", data.patientTimelineEventTemplates);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getChecklistTemplates({ commit, rootGetters }) {
      try {
        const { data } = await getSystemChecklists(
          rootGetters.organization.id,
          [
            "id",
            "description",
            "name",
            "onboarding",
            "items",
            "updatedAt",
            "createdAt",
          ],
        );
        commit("setChecklistTemplates", data);
      } catch (error) {
        logger.error(error as Error);
      }
    },
  },
  getters: {
    isPatientFilteringActive: (state) => {
      return state.isPatientFilteringActive;
    },
    alertFeedbackConfigs: (state) => {
      return state.alertFeedbackConfigs;
    },
    bodytraceTruncationEnabled: (state) => {
      return state.bodytraceTruncation;
    },
    listPageConfigs: (state) => {
      return state.listPageConfigs;
    },
    visibleListPageReadings: (state) => {
      if (!state.listPageConfigs) {
        return [];
      }

      return listPageOrderedReadings.filter(
        (k) => state.listPageConfigs[`show_values___${k}`],
      );
    },
    resourceTypes: (state) => {
      return state.resourceTypes;
    },
    virtualResourceTypes: (state) => {
      return state.resourceTypes.filter((r) => r.isVirtual);
    },
    showDeviceState: (state) => {
      return state.showDeviceState;
    },
    allRolesMap: (state) => {
      const roleMap: Record<string, ProviderRole | undefined> = {};
      for (const c of state.providerRoles ?? []) {
        roleMap[c.id] = c;
      }
      return roleMap;
    },
    allPatientTypesMap: (state) => {
      const typesMap: Record<string, IPatientType | undefined> = {};
      for (const c of state.patientTypes ?? []) {
        typesMap[c.id] = c;
      }
      return typesMap;
    },
    campaignTemplatesList(state) {
      return state.campaignTemplatesList;
    },
    campaignNotificationIndicatorEnabled(state) {
      return state.campaignNotificationIndicatorEnabled;
    },
    userLocations(state) {
      return state.userLocations;
    },
    ehrEnableUpdates(state) {
      return state.ehrEnableUpdates;
    },
    riskScoreConfigs(state) {
      return state.riskScoreConfigs;
    },
    diagnosesList(state) {
      return state.diagnoses;
    },
    patientWatchlistsEnabled(state) {
      return state.patientWatchlistsEnabled;
    },
    patientEventTemplates(state) {
      return state.patientEventTemplates;
    },
    patientTaskTemplates(state) {
      return state.patientTaskTemplates;
    },
    enableReadRequiredSwitch(state) {
      return state.enableReadRequiredSwitch;
    },
    checklistTemplates(state) {
      return state.checklistTemplates;
    },
    patientListsSchedulesPopover(state) {
      return state.patientListsSchedulesPopover;
    },
    isHttpErrorToastVisible(state) {
      return state.showHtpErrorToaster;
    },
    showHttpErrorInVideoCallPage(state) {
      return state.showHtpErrorToasterInVideoCallPage;
    },
    isRiskDataVisible(state) {
      return state.showRiskDataInDetailsPage;
    },
    isMessageReadReceiptsVisible(state) {
      return state.showMessageReadReceipts;
    },
    showAdmissionsAsOngoing(state) {
      return state.showAdmissionsAsOngoing;
    },
    showReadRequiredFeature(state) {
      return state.showReadRequiredFeature;
    },
    hideTroubleshootingTab(state) {
      return state.hideTroubleshootingTab;
    },
    showTemperatureInCelsius(state) {
      return state.showTemperatureInCelsius;
    },
    riskDataTimeRange(state) {
      return state.riskDataTimeRange;
    },
    showRiskScoreTrend(state) {
      return state.showRiskScoreTrend;
    },
    chartDisplayConfigs(state) {
      return state.chartConfigs;
    },
    techSupportConfigs(state) {
      return state.techSupportConfigs;
    },
    paginationFeatures(state) {
      return state.paginationFeatures;
    },
    allowCrossOrganizationCalls(state) {
      return state.allowCrossOrganizationCalls;
    },
    checkTabletAvailabilityBeforeVideoCall(state) {
      return state.checkTabletAvailabilityBeforeVideoCall;
    },
    smsConfigs(state) {
      return state.smsConfigs;
    },
    showDuplicateNameIndicator(state) {
      return state.showDuplicateNameIndicator;
    },
    showPoolRegion(state) {
      return state.showPoolRegion;
    },
    hospitalName(state) {
      return state.hospitalName;
    },
    enableVideoFeedback(state) {
      return state.enableVideoFeedback;
    },
    videoFeedbackOptions(state) {
      return state.videoFeedbackOptions;
    },
    poorVideoFeedbackOptions(state) {
      return state.poorVideoFeedbackOptions;
    },
    systemDefaultPhoto(state) {
      return state.systemPhoto;
    },
    providerDefaultPhoto(state) {
      return state.providerDefaultPhoto;
    },
    disableThresholdEngineChanges(state) {
      return state.disableChanges;
    },
  },
  mutations: {
    setGlobals(state, globals: Partial<Record<string, IGlobalSettingEntry>>) {
      state.systemPhoto = globals.orgPhoto?.value;
      state.providerDefaultPhoto = globals.providerPhoto?.value;
      state.chartConfigs =
        globals.SETTINGS___PROVIDER_PORTAL_CHART_CONFIGS?.value;
      state.techSupportConfigs =
        globals.SETTINGS___PROVIDER_PORTAL_TECH_SUPPORT_CONFIGS?.value;
      state.listPageConfigs =
        globals.SETTINGS___PROVIDER_PORTAL_LIST_PAGE_CONFIGS?.value;
      state.alertFeedbackConfigs = globals.SETTINGS___FEEDBACK_FEATURES?.value;
      state.paginationFeatures = globals.SETTINGS___PAGINATION_FEATURES?.value;
      state.patientWatchlistsEnabled =
        !!globals.patientWatchlistsEnabled?.value;
      state.bodytraceTruncation =
        globals.SETTINGS___RESOURCE_FEATURES?.value?.pp_bodytrace_value_truncation;
      state.showTemperatureInCelsius =
        globals.SETTINGS___RESOURCE_FEATURES?.value?.pp_temperature_measurement_celsius;
      state.ehrEnableUpdates =
        globals.SETTINGS___EHR_CONFIG?.value?.enable_ehr_updates;
      state.showHtpErrorToaster =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_http_error_messages;
      state.showHtpErrorToasterInVideoCallPage =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_http_error_in_video_call_page;
      state.showRiskDataInDetailsPage =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_risk_data_in_details_page;
      state.riskDataTimeRange =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.risk_score_date_range;
      state.showRiskScoreTrend =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.arrow_based_on_score_trend;
      state.enableReadRequiredSwitch =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.enable_read_required_switch;
      state.showMessageReadReceipts =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_message_read_receipts;
      state.showDeviceState =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_device_state;
      state.showAdmissionsAsOngoing =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_ongoing_admissions;
      state.showReadRequiredFeature =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_read_required_feature;
      state.showDuplicateNameIndicator =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_duplicate_name_indicator;
      state.showPoolRegion =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.show_pool_region;
      state.hideTroubleshootingTab =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.hide_support_troubleshooting;
      state.hospitalName =
        globals.SETTINGS___PROVIDER_PORTAL_ENVIRONMENT_CONFIGS?.value?.hospital_system;
      state.campaignNotificationIndicatorEnabled =
        globals.SETTINGS___CAMPAIGN_FEATURES?.value?.enable_campaign_notification_indicator;
      state.diagnoses = (
        globals.SETTINGS___PATIENTS?.value?.diagnoses || []
      ).map((d: string) => d.toUpperCase());
      state.allowCrossOrganizationCalls =
        globals.SETTINGS___COMMUNICATIONS_CONFIGS?.value?.allow_cross_organization_video_calls;
      state.checkTabletAvailabilityBeforeVideoCall =
        globals.SETTINGS___COMMUNICATIONS_CONFIGS?.value?.check_tablet_availability_before_video_call;
      state.smsConfigs = {
        primarySMSNumber:
          globals.SETTINGS___COMMUNICATIONS_CONFIGS?.value?.primary_sms_number,
        secondarySMSNumber:
          globals.SETTINGS___COMMUNICATIONS_CONFIGS?.value
            ?.secondary_sms_number,
      };
      state.enableVideoFeedback =
        globals.SETTINGS___VIDEO_QUALITY_FEEDBACK_CONFIGS?.value?.enable_video_feedback;
      state.videoFeedbackOptions =
        globals.SETTINGS___VIDEO_QUALITY_FEEDBACK_CONFIGS?.value?.video_feedback_options;
      state.poorVideoFeedbackOptions =
        globals.SETTINGS___VIDEO_QUALITY_FEEDBACK_CONFIGS?.value?.poor_video_feedback_options;
      state.disableChanges =
        globals.SETTINGS___THRESHOLD_FEATURES?.value?.disable_threshold_engine_changes;

      const scoreConfigs = Array.isArray(
        globals.SETTINGS___RISK_SCORE_CONFIGS?.value,
      )
        ? globals.SETTINGS___RISK_SCORE_CONFIGS?.value
        : Object.values(globals.SETTINGS___RISK_SCORE_CONFIGS?.value || {});

      state.riskScoreConfigs = (scoreConfigs || [])?.sort(
        (a, b) => a.maxScore - b.maxScore,
      );
    },
    setFilterDropdownConfigs(state, filterState: boolean) {
      state.isPatientFilteringActive = filterState;
    },
    setCampaignTemplatesList(state, campaignList: CampaignTemplateList[]) {
      state.campaignTemplatesList = campaignList;
    },
    setResourceTypes(state, types: ResourceType[]) {
      state.resourceTypes = types;
    },
    setProviderRoles(state, roles: ProviderRole[]) {
      state.providerRoles = roles;
    },
    setPatientTypes(state, patientTypes: IPatientType[]) {
      state.patientTypes = patientTypes;
    },
    updatePatientTypes(state, patientTypes: IPatientType[]) {
      const typeMap = patientTypes.reduce((p: any, c: IPatientType) => {
        p[c.id] = c;
        return p;
      }, {} as Record<string, IPatientType | undefined>);
      state.patientTypes = state.patientTypes.map((t) => {
        return {
          ...t,
          ...typeMap[t.id],
        };
      });
    },
    setUserLocations(state, locations: UserLocation[]) {
      state.userLocations = locations;
    },
    setPatientEventTemplates(
      state,
      eventTemplates: IPatientTimelineEventTemplate[],
    ) {
      state.patientEventTemplates = eventTemplates;
    },
    setPatientTaskTemplates(
      state,
      taskTemplates: IPatientTimelineTaskTemplate[],
    ) {
      state.patientTaskTemplates = taskTemplates;
    },
    setChecklistTemplates(state, checklists: IChecklist[]) {
      state.checklistTemplates = checklists;
    },
    togglePatientListSchedulesPopover(
      state,
      popoverPayload: PatientListsSchedulesPopoverPayload | null,
    ) {
      state.patientListsSchedulesPopover = state.isPatientFilteringActive
        ? null
        : popoverPayload;
    },
  },
};

export default module;
