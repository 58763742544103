import {
  APPOINTMENT_PASSED_THRESHOLD,
  DATE_FORMAT_YYYY_MM_DD,
  DAY_IN_MILLISECONDS,
  DEFAULT_TIMEZONE,
  ID_SEPARATOR,
  KG_TO_LB_MULTIPLIER,
  REPEATING_EVENT_FORWARD_LIMIT,
  REPEATING_EVENT_MIN_RANGE,
  ResourceIdEnum,
} from "@/configs/constants";
import {
  getGranularTimeListForCharts,
  getTimeListForCharts,
  getValuesForCharts,
} from "@/factories/chart-factory";
import { truncateBodytraceValue } from "@/services/patient.parser";
import store from "@/store/store";
import later from "@breejs/later";
import {
  PatientIdentifier,
  PatientTimelineEvent,
  PatientTimelineTask,
  Resource,
} from "generated/types-gql";
import moment from "moment-timezone";

import {
  IPatient,
  IPatientLatestVitals,
  IPatientReviewDay,
  IPatientScheduledAppointment,
  IPatientStats,
  IPatientStructure,
  IPatientTarget,
  IRawStat,
  IResourceStatus,
} from "../../@types/patient";
import { isAdmissionEhr } from "./event-factory";

export enum ProviderPatientActivityTypes {
  addTaggedEvent = "addTaggedEvent",
  addTarget = "addTarget",
  answerPRO = "answerPRO",
  completeTaggedEvent = "completeTaggedEvent",
  completeTaggedEventUndo = "completeTaggedEventUndo",
  deleteTaggedEvent = "deleteTaggedEvent",
  deleteTaggedEventUndo = "deleteTaggedEventUndo",
  deleteTargetUndo = "deleteTargetUndo",
  deleteTrace = "deleteTrace",
  deleteTraceUndo = "deleteTraceUndo",
  deleteVital = "deleteVital",
  deleteVitalUndo = "deleteVitalUndo",
  editTaggedEvent = "editTaggedEvent",
  reviewTaggedEvent = "reviewTaggedEvent",
  editTarget = "editTarget",
  editThreshold = "editThreshold",
  finishPRO = "finishPRO",
  patientJoined = "patientJoined",
  patientView = "patientView",
  removeTarget = "deleteTarget",
  reschedule = "reschedule",
  rescheduleUndo = "rescheduleUndo",
  reviewComplete = "reviewComplete",
  reviewCompleteUndo = "reviewCompleteUndo",
  sendingPRO = "sendingPRO",
  supportRequest = "supportRequest",
  supportResolve = "supportResolve",
  thresholdWarning = "thresholdWarning",
  addTimelineEvent = "addTimelineEvent",
  addTimelineTask = "addTimelineTask",
  editTimelineEvent = "editTimelineEvent",
  editTimelineTask = "editTimelineTask",
  completeTimelineTask = "completeTimelineTask",
  completeTimelineTaskUndo = "completeTimelineTaskUndo",
  deleteTimelineEvent = "deleteTimelineEvent",
  deleteTimelineTask = "deleteTimelineTask",
  deleteTimelineEventUndo = "deleteTimelineEventUndo",
  deleteTimelineTaskUndo = "deleteTimelineTaskUndo",
}

export const BOUNDARY: number = 7;

export const resourceStatusesOfVitalKind = (
  resources: IResourceStatus[],
  kind: string,
) => {
  if (!resources?.length) {
    return [];
  }
  const kindByDataMode = kind.includes(ID_SEPARATOR)
    ? kind.split(ID_SEPARATOR)[1]
    : kind;
  switch (kindByDataMode) {
    case "hr":
    case "rr":
    case "avgHr":
    case "avgRr":
    case "sleep":
    case "maxHr":
    case "minHr":
    case "maxRr":
    case "minRr":
    case "durationInSleep":
    case "durationInBed":
      return resources.filter(
        (r) =>
          r.id.includes(ResourceIdEnum.BioIntellisense) ||
          r.id.includes(ResourceIdEnum.Emfit) ||
          r.id.includes(ResourceIdEnum.WithingsSleepSensor),
      );
    case "weight":
      return resources.filter(
        (r) =>
          r.id.includes(ResourceIdEnum.Yolanda) ||
          r.id.includes(ResourceIdEnum.WithingsScale) ||
          r.id.includes(ResourceIdEnum.AndScale) ||
          r.id.includes(ResourceIdEnum.AndScaleSecondary) ||
          r.id.includes(ResourceIdEnum.BodytraceScale),
      );
    case "sbp":
    case "systolicBp":
    case "diastolicBp":
      return resources.filter(
        (r) =>
          r.id.includes(ResourceIdEnum.Omron) ||
          r.id.includes(ResourceIdEnum.AndBP) ||
          r.id.includes(ResourceIdEnum.BodytraceBloodPressure) ||
          r.id.includes(ResourceIdEnum.WithingsBP),
      );
    case "pulseRate":
      return resources.filter(
        (r) =>
          r.id.includes(ResourceIdEnum.BioIntellisense) ||
          r.id.includes(ResourceIdEnum.Omron) ||
          r.id.includes(ResourceIdEnum.AndBP) ||
          r.id.includes(ResourceIdEnum.WithingsBP) ||
          r.id.includes(ResourceIdEnum.Contec) ||
          r.id.includes(ResourceIdEnum.ContecV1_7) ||
          r.id.includes(ResourceIdEnum.Nonin),
      );
    case "o2":
      return resources.filter(
        (r) =>
          r.id.includes(ResourceIdEnum.Contec) ||
          r.id.includes(ResourceIdEnum.ContecV1_7) ||
          r.id.includes(ResourceIdEnum.Nonin),
      );
    case "glucoseFasting":
    case "glucosePreMeal":
    case "glucosePostMeal":
      return resources.filter(
        (r) =>
          r.id.includes(ResourceIdEnum.ManualBloodGlucose) ||
          r.id.includes(ResourceIdEnum.ManualBloodGlucoseProvider),
      );
    case "riskClass":
    case "riskScore":
      return resources.filter(
        (r) =>
          r.id.includes(ResourceIdEnum.MachineLearningGrad) ||
          r.id.includes(ResourceIdEnum.MachineLearningCGP) ||
          r.id.includes(ResourceIdEnum.MachineLearningIEI),
      );
    case "medianTemperature":
    case "medianRestRr":
    case "medianRestHr":
    case "temperature":
      return resources.filter((r) =>
        r.id.includes(ResourceIdEnum.BioIntellisense),
      );
    default:
      return [];
  }
};

export const hasResourceOfVitalKind = (resources: Resource[], kind: string) => {
  const resourceIdPrefixes = resources.map((k) => k.id.split(ID_SEPARATOR)[0]);
  const kindByDataMode = kind.includes(ID_SEPARATOR)
    ? kind.split(ID_SEPARATOR)[1]
    : kind;
  switch (kindByDataMode) {
    case "hr":
    case "rr":
    case "avgHr":
    case "avgRr":
    case "sleep":
    case "maxHr":
    case "minHr":
    case "maxRr":
    case "minRr":
    case "durationInSleep":
    case "durationInBed":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.Emfit) ||
        resourceIdPrefixes.includes(ResourceIdEnum.WithingsSleepSensor)
      );
    case "riskClass":
    case "riskScore":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.MachineLearningGrad) ||
        resourceIdPrefixes.includes(ResourceIdEnum.MachineLearningCGP) ||
        resourceIdPrefixes.includes(ResourceIdEnum.MachineLearningIEI)
      );
    case "weight":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.ManualScale) ||
        resourceIdPrefixes.includes(ResourceIdEnum.ManualScaleProvider) ||
        resourceIdPrefixes.includes(ResourceIdEnum.Yolanda) ||
        resourceIdPrefixes.includes(ResourceIdEnum.WithingsScale) ||
        resourceIdPrefixes.includes(ResourceIdEnum.AndScale) ||
        resourceIdPrefixes.includes(ResourceIdEnum.AndScaleSecondary) ||
        resourceIdPrefixes.includes(ResourceIdEnum.BodytraceScale) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTScale) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTManualScale) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTManualScaleProvider)
      );
    case "sbp":
    case "systolicBp":
    case "diastolicBp":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.ManualBloodPressure) ||
        resourceIdPrefixes.includes(
          ResourceIdEnum.ManualBloodPressureProvider,
        ) ||
        resourceIdPrefixes.includes(ResourceIdEnum.Omron) ||
        resourceIdPrefixes.includes(ResourceIdEnum.AndBP) ||
        resourceIdPrefixes.includes(ResourceIdEnum.BodytraceBloodPressure) ||
        resourceIdPrefixes.includes(ResourceIdEnum.WithingsBP) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTBP) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTManualBP) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTManualBPProvider)
      );
    case "pulseRate":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.ManualPulseOximeter) ||
        resourceIdPrefixes.includes(
          ResourceIdEnum.ManualPulseOximeterProvider,
        ) ||
        resourceIdPrefixes.includes(ResourceIdEnum.Omron) ||
        resourceIdPrefixes.includes(ResourceIdEnum.AndBP) ||
        resourceIdPrefixes.includes(ResourceIdEnum.WithingsBP) ||
        resourceIdPrefixes.includes(ResourceIdEnum.Contec) ||
        resourceIdPrefixes.includes(ResourceIdEnum.ContecV1_7) ||
        resourceIdPrefixes.includes(ResourceIdEnum.Nonin) ||
        resourceIdPrefixes.includes(ResourceIdEnum.BioIntellisense)
      );
    case "o2":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.ManualPulseOximeter) ||
        resourceIdPrefixes.includes(
          ResourceIdEnum.ManualPulseOximeterProvider,
        ) ||
        resourceIdPrefixes.includes(ResourceIdEnum.Contec) ||
        resourceIdPrefixes.includes(ResourceIdEnum.ContecV1_7) ||
        resourceIdPrefixes.includes(ResourceIdEnum.Nonin) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTPulseOx) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTManualPulseOx) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTManualPulseOxProvider)
      );
    case "glucoseFasting":
    case "glucosePreMeal":
    case "glucosePostMeal":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.ManualBloodGlucose) ||
        resourceIdPrefixes.includes(
          ResourceIdEnum.ManualBloodGlucoseProvider,
        ) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTBloodGlucose) ||
        resourceIdPrefixes.includes(ResourceIdEnum.LSTManualBloodGlucose) ||
        resourceIdPrefixes.includes(
          ResourceIdEnum.LSTManualBloodGlucoseProvider,
        )
      );
    case "temperature":
    case "medianTemperature":
      return (
        resourceIdPrefixes.includes(ResourceIdEnum.BioIntellisense) ||
        resourceIdPrefixes.includes(ResourceIdEnum.ManualTemperatureProvider) ||
        resourceIdPrefixes.includes(ResourceIdEnum.ManualTemperature)
      );
    case "medianRestRr":
    case "medianRestHr":
      return resourceIdPrefixes.includes(ResourceIdEnum.BioIntellisense);
    default:
      return false;
  }
};

export const getPatientListVitalProjections = () => {
  return [
    "pulseRate",
    "systolicBp",
    "diastolicBp",
    "weight",
    "o2",
    "glucosePreMeal",
    "glucosePostMeal",
    "glucoseFasting",
    "temperature",
    "medianTemperature",
    "medianRestRr",
    "DATA___riskScore",
    "DATA___riskClass",
  ];
};

export const getVitalProjections = () => {
  const projections = [
    "date",
    "avgHr",
    "avgRr",
    "maxHr",
    "minHr",
    "maxRr",
    "minRr",
    "durationInSleep",
    "durationInBed",
    "pulseRate",
    "weight",
    "systolicBp",
    "o2",
    "diastolicBp",
    "glucoseFasting",
    "glucosePreMeal",
    "glucosePostMeal",
    "temperature",
    "medianTemperature",
    "minTemperature",
    "maxTemperature",
    "maxRestHr",
    "minRestHr",
    "medianRestRr",
    "maxRestRr",
    "minRestRr",
    "DATA___riskScore",
    "DATA___riskClass",
  ];

  const resources = store.getters.resources || [];
  const rawStats: IRawStat[] | undefined = (store.getters.regularVitals || {})
    ?.rawStats;

  return resources.length === 0 || rawStats == null
    ? projections
    : projections.filter(
        (p) =>
          p === "date" ||
          hasResourceOfVitalKind(resources, p) ||
          rawStats?.some((s) => s[p]?.length > 0),
      );
};

export const getUniqueIdentifiers = (identifiers: PatientIdentifier[]) => {
  const identifiersMap: Record<string, PatientIdentifier> = {};

  for (const identifier of identifiers) {
    const key = `${identifier.ID}#${identifier.IDType || ""}#${
      identifier.IDAddedAt || ""
    }`;
    identifiersMap[key] = identifier;
  }

  return Object.values(identifiersMap);
};

export const isRepeatingEvent = (
  event: PatientTimelineEvent,
  patient?: IPatientStructure | null,
) => {
  if (event.eventType !== "milestone" || !event.repeatCron) {
    return false;
  }
  const count = Math.ceil(
    (REPEATING_EVENT_FORWARD_LIMIT + Date.now()) / REPEATING_EVENT_MIN_RANGE,
  );
  later.date.localTime();
  const results = later
    .schedule(later.parse.cron(event.repeatCron))
    .next(
      count,
      Date.now() - DAY_IN_MILLISECONDS,
      new Date(REPEATING_EVENT_FORWARD_LIMIT + Date.now()),
    );
  if (!results) {
    return false;
  }

  return (Array.isArray(results) ? results : [results])?.find(
    (date: string) => {
      const formattedDate = moment
        .tz(date, moment.tz.guess())
        .format(DATE_FORMAT_YYYY_MM_DD);
      const timestamp = moment
        .tz(formattedDate, patient?.pii?.timezone || DEFAULT_TIMEZONE)
        .valueOf();
      return timestamp >= moment(Date.now()).startOf("day").valueOf();
    },
  );
};

export const sortByAlerts = (patients: IPatient[]) => {
  return patients.sort((a, b) => {
    const severityA = +(a.totalAlertSeverity ?? 0);
    const severityB = +(b.totalAlertSeverity ?? 0);
    return severityB - severityA;
  });
};

export const getAdditionalProperties = (
  patient: IPatient,
): Partial<IPatient> => {
  const notificationTypes = patient.notifications?.map((n) => n.type);

  let fullName;
  if (patient?.pii == null) {
    fullName = "";
  } else {
    const { firstName, lastName, preferredName } = patient.pii;
    const preferred = preferredName ? `"${preferredName}"` : "";
    fullName = `${lastName} ${firstName} ${preferred}`;
  }
  const timezone = patient?.pii?.timezone;

  return {
    $notificationTypes: notificationTypes,
    $fullName: fullName.toLowerCase(),
    pii: patient.pii
      ? {
          ...patient?.pii,
          timezone: timezone === "US/Pacific-New" ? "US/Pacific" : timezone,
        }
      : undefined,
  };
};

export const setPatientListItemDataLoaded = (patient: IPatient) => {
  return {
    ...patient,
    $dataLoaded: true,
  };
};

export const getNextAppointment = (
  appointments?: IPatientScheduledAppointment[] | null,
) => {
  return (appointments || []).sort(
    (a, b) => getScheduledAppointmentDate(a) - getScheduledAppointmentDate(b),
  );
};

export const sortByReviewDate = (patients: IPatient[]) => {
  const patientsWithAppointments = [];
  const patientsWithTasks = [];
  const everyoneElse = [];

  for (const p of patients) {
    if (getEarliestAppointmentDate(p)) {
      patientsWithAppointments.push(p);
    } else if (p.earliestTaskDate) {
      patientsWithTasks.push(p);
    } else {
      everyoneElse.push(p);
    }
  }

  const patientsWithData = [...patientsWithAppointments, ...patientsWithTasks];
  patientsWithData.sort((patientA, patientB) => {
    if (!patientA || !patientB) {
      return -1;
    }

    const appDateA = getEarliestAppointmentDate(patientA);
    const appDateB = getEarliestAppointmentDate(patientB);

    const dateA =
      appDateA && patientA.earliestTaskDate
        ? Math.min(appDateA, patientA.earliestTaskDate)
        : appDateA || patientA.earliestTaskDate;

    const dateB =
      appDateB && patientB.earliestTaskDate
        ? Math.min(appDateB, patientB.earliestTaskDate)
        : appDateB || patientB.earliestTaskDate;

    if (!dateA || !dateB) {
      return -1;
    }

    if (dateA == dateB) {
      return patientA.id < patientB.id ? -1 : 1;
    }

    return dateA - dateB;
  });

  return [...patientsWithData, ...sortByAlerts(everyoneElse)];
};

export const sortByAlphabetic = (patients: IPatient[], ascending?: boolean) => {
  return patients.sort((patientA, patientB) => {
    const aName = patientA.$fullName || "";
    const bName = patientB.$fullName || "";
    return ascending ? aName.localeCompare(bName) : bName.localeCompare(aName);
  });
};

export const sortByRecentlyViewed = (patients: IPatient[]) => {
  patients.sort(
    (patientA, patientB) =>
      (patientB.lastViewInfo?.updatedAt || 0) -
      (patientA.lastViewInfo?.updatedAt || 0),
  );
  return patients;
};

const hasValues = (vitals: IPatientLatestVitals, type: string) => {
  if (vitals == null) {
    return false;
  }
  return vitals[type] && vitals[type].length;
};

// TODO: add as a filter(filter not working, added as a function)
export const roundFilter = (value: number, decimals?: number) => {
  if (!value) {
    value = 0;
  }
  if (!decimals) {
    decimals = 0;
  }
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
};

export const getNormalizedWeightValue = (
  value: number,
  resourceId?: string | null,
) => {
  return shouldTruncateBodytraceValue(resourceId || "")
    ? truncateBodytraceValue(value * KG_TO_LB_MULTIPLIER)
    : Number((value * KG_TO_LB_MULTIPLIER).toFixed(1));
};

export const getNormalizedTemperatureValue = (value: number | null) => {
  if (!value) {
    return null;
  }
  const data = store?.getters?.showTemperatureInCelsius
    ? value
    : convertCelsiusToFahrenheit(value);
  return Math.round(data * 10) / 10;
};

export const convertCelsiusToFahrenheit = (value: number) => {
  return value * 1.8 + 32;
};

export const convertFahrenheitToCelsius = (value: number) => {
  return (value - 32) / 1.8;
};

export const shouldTruncateBodytraceValue = (resourceId: string) => {
  return (
    store?.getters?.bodytraceTruncationEnabled &&
    resourceId.includes(ResourceIdEnum.BodytraceScale)
  );
};

export const getMeasurementName = (type: string) => {
  if (
    type === "hr" ||
    type === "rr" ||
    type === "pulseRate" ||
    type === "medianRestRr"
  ) {
    return "bpm";
  } else if (type === "weight") {
    return "lbs";
  } else if (type === "sleep") {
    return "hrs";
  } else if (type === "sbp") {
    return "mmHg";
  } else if (type === "walk") {
    return "steps";
  } else if (type === "o2") {
    return "%";
  } else if (type === "glucoseFasting") {
    return "mg/dl";
  } else if (type === "medianTemperature" || type === "temperature") {
    return store?.getters?.showTemperatureInCelsius ? "°C" : "°F";
  }
  return "unknown";
};

export const getPatientStatsWithFullStatDays = (
  stats: IPatientStats,
  month?: string | string[],
  endDate?: string,
  makeGranular?: boolean,
) => {
  const kinds = [
    "hr",
    "pulseRate",
    "rr",
    "weight",
    "sleep",
    "o2",
    "glucoseFasting",
    "glucosePostMeal",
    "glucosePreMeal",
    "emojis",
    "inBed",
    "sbp",
    "diastolicBp",
    "walk",
    "dailySymptoms",
    "dailyBreathing",
    "dailySwelling",
    "medianRestHr",
    "maxRestHr",
    "minRestHr",
    "medianTemperature",
    "maxTemperature",
    "minTemperature",
    "medianRestRr",
    "maxRestRr",
    "minRestRr",
  ];
  if (stats && stats.timestamp) {
    const oldTimeLine = stats.timestamp.slice(0);
    stats.timestamp = makeGranular
      ? getGranularTimeListForCharts(stats.timestamp, endDate)
      : getTimeListForCharts(stats.timestamp, month, endDate);
    for (const k of kinds) {
      if (stats.vitals?.[k]) {
        stats.vitals[k].values = getValuesForCharts(
          stats.timestamp,
          oldTimeLine,
          stats.vitals[k].values,
        );
      }
    }
  }
  return stats;
};

// this is for new timeline structure in details page
export const getPatientTimelineItemsByDate = (
  patient: IPatientStructure | null,
  timelineEvents: PatientTimelineEvent[],
  timelineTasks: PatientTimelineTask[],
  appointments: IPatientScheduledAppointment[],
) => {
  const reviewDays: IPatientReviewDay[] = [];
  const today = moment
    .tz(patient?.pii?.timezone || DEFAULT_TIMEZONE)
    .startOf("day")
    .valueOf();
  timelineEvents
    .filter((e) => {
      return (
        e.eventType !== "alert" &&
        e.eventType !== "customEvent" &&
        (e.startDate >= today ||
          isRepeatingEvent(e, patient) ||
          (e.endDate >= today && e.startDate <= today) ||
          (e.startDate <= today &&
            (e.isOngoing ||
              (store?.getters?.showAdmissionsAsOngoing && isAdmissionEhr(e)))))
      );
    })
    .forEach((e) => {
      let eventDates: number[] = [];
      if (e.eventType === "milestone" && e.repeatCron) {
        const count = Math.ceil(
          (REPEATING_EVENT_FORWARD_LIMIT + Date.now()) /
            REPEATING_EVENT_MIN_RANGE,
        );
        later.date.localTime();
        const results = later
          .schedule(later.parse.cron(e.repeatCron))
          .next(
            count,
            today - DAY_IN_MILLISECONDS,
            new Date(REPEATING_EVENT_FORWARD_LIMIT + Date.now()),
          );
        if (results) {
          eventDates = (Array.isArray(results) ? results : [results]).map(
            (d: string) => {
              const dateFormat = moment
                .tz(d, moment.tz.guess())
                .format(DATE_FORMAT_YYYY_MM_DD);
              return moment
                .tz(dateFormat, patient?.pii?.timezone || DEFAULT_TIMEZONE)
                .valueOf();
            },
          );
        }
      } else {
        eventDates.push(e.startDate);
      }
      eventDates.forEach((reviewDate) => {
        const date = moment
          .tz(
            reviewDate == null || reviewDate < Date.now()
              ? Date.now()
              : reviewDate,
            patient?.pii?.timezone || DEFAULT_TIMEZONE,
          )
          .format(DATE_FORMAT_YYYY_MM_DD);
        const index = reviewDays.findIndex((ev) => ev.date === date);

        if (index === -1) {
          reviewDays.push({ date, timelineEvents: [e] });
        } else {
          reviewDays[index] = {
            ...reviewDays[index],
            date,
            timelineEvents: [...(reviewDays[index].timelineEvents || []), e],
          };
        }
      });
    });
  timelineTasks
    .filter((t) => !t.completedAt)
    .forEach((t) => {
      const reviewDate = getTimelineTaskDate(t);
      const date = moment
        .tz(
          reviewDate == null || reviewDate < Date.now()
            ? Date.now()
            : reviewDate,
          patient?.pii?.timezone || DEFAULT_TIMEZONE,
        )
        .format(DATE_FORMAT_YYYY_MM_DD);
      const index = reviewDays.findIndex((ev) => ev.date === date);

      if (index === -1) {
        reviewDays.push({ date, tasks: [t] });
      } else {
        reviewDays[index] = {
          ...reviewDays[index],
          date,
          tasks: [...(reviewDays[index].tasks || []), t],
        };
      }
    });

  appointments
    .filter(
      (a) =>
        !a.reviewed &&
        a.date != null &&
        getScheduledAppointmentDate(a) + APPOINTMENT_PASSED_THRESHOLD >
          Date.now(),
    )
    .forEach((a) => {
      const reviewDate = getScheduledAppointmentDate(a);
      const date = moment
        .tz(
          reviewDate == null || reviewDate < Date.now()
            ? Date.now()
            : reviewDate,
          patient?.pii?.timezone || DEFAULT_TIMEZONE,
        )
        .format(DATE_FORMAT_YYYY_MM_DD);
      const index = reviewDays.findIndex((ev) => ev.date === date);

      if (index === -1) {
        reviewDays.push({ date, scheduledAppointments: [a] });
      } else {
        reviewDays[index] = {
          ...reviewDays[index],
          date,
          scheduledAppointments: [
            ...(reviewDays[index].scheduledAppointments || []),
            a,
          ],
        };
      }
    });

  return reviewDays.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
};

export const getTimelineTaskDate = (e: PatientTimelineTask) => {
  const postponed = e.postponed
    ?.filter((ev) => ev.timestamp != null)
    .sort((b, a) => {
      return a.timestamp - b.timestamp;
    })[0]?.postponedTo;
  return postponed || e.taskDate;
};

export const getScheduledAppointmentDate = (
  appointment: IPatientScheduledAppointment | null,
) => {
  const lastPostpone = [...(appointment?.postponed || [])].sort(
    (a, b) => (b.timestamp || 0) - (a.timestamp || 0),
  )[0];
  return (
    lastPostpone?.postponedTo ||
    appointment?.followupDate ||
    appointment?.date ||
    0
  );
};

export const getEarliestAppointmentDate = (patient: IPatient) => {
  const APP_THRESHOLD_TS = Date.now() - APPOINTMENT_PASSED_THRESHOLD;

  if (
    patient.earliestAppointmentDate &&
    patient.earliestAppointmentDate > APP_THRESHOLD_TS
  ) {
    return patient.earliestAppointmentDate;
  }

  const validDates = (patient.appointmentPayloads || [])
    .filter((app) => app.timestamp > APP_THRESHOLD_TS)
    .map((app) => app.timestamp);
  return validDates.length > 0 ? Math.min(...validDates) : null;
};

export const hasVitalOfKind = (stats: IPatientLatestVitals, kind: string) => {
  switch (kind) {
    case "glucoseFasting":
      return (
        hasValues(stats || {}, kind) ||
        hasValues(stats || {}, "glucosePreMeal") ||
        hasValues(stats || {}, "glucosePostMeal")
      );
    case "survey":
      return (
        hasValues(stats || {}, "dailySymptoms") ||
        hasValues(stats || {}, "dailyBreathing") ||
        hasValues(stats || {}, "dailySwelling")
      );
    case "medianTemperature":
      return (
        hasValues(stats || {}, "medianTemperature") ||
        hasValues(stats || {}, "temperature")
      );
    case "sbp":
      return (
        hasValues(stats || {}, "systolicBp") ||
        hasValues(stats || {}, "diastolicBp")
      );
    case "rr":
    case "avgRr":
      return hasValues(stats || {}, "avgRr");
    case "hr":
    case "avgHr":
      return hasValues(stats || {}, "avgHr");
    case "sleep":
    case "durationInSleep":
      return hasValues(stats || {}, "durationInSleep");
    default:
      return hasValues(stats || {}, kind);
  }
};

export const sortTargetsByType = (targets: IPatientTarget[] | null) => {
  const priorityValues: any = {
    emoji: 4,
    hr: 3,
    rr: 2,
    pulseRate: 15,
    o2: 13,
    glucose: 12,
    sbp: 14,
    sleep: 1,
    steps: 6,
    weight: 11,
  };

  return targets?.sort((a, b) => {
    if (!b || !a || !(a && b)) {
      return -1;
    }
    if (!b.marker || !a.marker || !(a.marker && b.marker)) {
      return -1;
    }
    const scoreA = priorityValues[a.marker];
    const scoreB = priorityValues[b.marker];
    if (scoreA < scoreB) {
      return 1;
    } else if (scoreA > scoreB) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const resetFiltersFromStorage = () => {
  sessionStorage.setItem("filterBadge", "");
  sessionStorage.setItem("priorityFilterBadge", "");
  sessionStorage.setItem("sortBy", "");
  localStorage.setItem("sortBy", "");
  localStorage.setItem("assignedBy", "");
};

export const riskValueNormalizer = (diff: number) => {
  const absoluteValue = Math.round(diff * 100) / 100;
  if (absoluteValue >= 1 || absoluteValue === 0) {
    return absoluteValue;
  }
  return absoluteValue ? `.${(absoluteValue + "").split(".")[1] || ""}` : "";
};
