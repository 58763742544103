import { IProviderPatientActivity } from "../../../@types/patient";
import { Module } from "vuex";

import { IActivityLogsModuleState, IRootState } from "../../../@types/store";
import { Query } from "generated/types-gql";
import router from "@/router/routes";
import apollo from "@/apollo";
import logger from "@/logger";
import {
  GET_PATIENT_ACTIVITY_LOGS,
  GET_PATIENT_PAGINATED_ACTIVITY_LOGS,
} from "@/graphql/queries/patient.query";
import { PAGINATION_LIMITS } from "@/configs/constants";
import { ProviderPatientActivityTypes } from "@/factories/patient-factory";

const initialState: IActivityLogsModuleState = {
  patientActivityLogsMap: {},
  allActivityLogsLoaded: false,
  activityLogsScrollPosition: null,
  activityLogsFetchCounter: 1,
};

const module: Module<IActivityLogsModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getActivityLogs(
      { commit, rootGetters },
      {
        patientId,
        startKey,
        sort = "DSC",
      }: { patientId: string; startKey: string; sort: "ASC" | "DSC" },
    ) {
      try {
        let data: Query;
        if (!rootGetters.paginationFeatures.activity_logs) {
          const result = await apollo.query<Query>({
            query: GET_PATIENT_ACTIVITY_LOGS,
            variables: {
              patient: patientId,
            },
          });
          data = result.data;
        } else {
          const result = await apollo.query<Query>({
            query: GET_PATIENT_PAGINATED_ACTIVITY_LOGS,
            variables: {
              patient: patientId,
              startKey: startKey,
              rootOrgId: rootGetters.rootOrganization?.id,
              limit: PAGINATION_LIMITS.activity_logs || Infinity,
              sort,
              excludeLogsOfType: [
                ProviderPatientActivityTypes.patientView,
                ProviderPatientActivityTypes.reviewComplete,
                ProviderPatientActivityTypes.reviewCompleteUndo,
                ProviderPatientActivityTypes.deleteTaggedEvent,
                ProviderPatientActivityTypes.editTaggedEvent,
                ProviderPatientActivityTypes.completeTaggedEvent,
                ProviderPatientActivityTypes.deleteTaggedEventUndo,
                ProviderPatientActivityTypes.answerPRO,
              ],
            },
          });
          data = result.data;
        }
        if (
          (data.patient?.paginatedActivityLogsJSON || []).length <
            PAGINATION_LIMITS.activity_logs &&
          sort === "DSC" &&
          rootGetters.paginationFeatures.activity_logs
        ) {
          commit("setAllActivityLogsLoaded");
        }
        commit(
          "setPatientActivityLogs",
          rootGetters.paginationFeatures.activity_logs
            ? { logs: data.patient?.paginatedActivityLogsJSON, patientId }
            : { logs: data.patient?.activityLogsJSON || [], patientId },
        );
      } catch (error) {
        logger.error(error as Error);
      }
    },
  },
  getters: {
    patientActivityLogsMap: (state) => {
      return state.patientActivityLogsMap || {};
    },
    activityLogs: (state) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientActivityLogsMap?.[patientId] || [];
    },
    patientActivityTargetById: (state) => (id: string) => {
      if (!id) {
        return [];
      }
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientActivityLogsMap?.[patientId]?.filter(
        (i) => i.details?.target?.id === id,
      );
    },
    allActivityLogsLoaded(state) {
      return state.allActivityLogsLoaded;
    },
    activityLogsScrollPosition(state) {
      return state.activityLogsScrollPosition;
    },
    activityLogsFetchCounter(state) {
      return state.activityLogsFetchCounter;
    },
  },
  mutations: {
    setPatientActivityLogs(
      state,
      data: { logs: IProviderPatientActivity[]; patientId: string },
    ) {
      const uniqueLogs: IProviderPatientActivity[] = [];
      [
        ...(state.patientActivityLogsMap?.[data.patientId] || []),
        ...(data?.logs || []),
      ].forEach((item) => {
        const i = uniqueLogs.findIndex((x) => x.id === item.id);
        if (i <= -1) {
          uniqueLogs.push(item);
        } else if (item.createdAt > uniqueLogs[i].createdAt) {
          uniqueLogs[i] = item;
        }
      });
      state.patientActivityLogsMap[data.patientId] = uniqueLogs;
      state.patientActivityLogsMap = { ...state.patientActivityLogsMap };
    },
    setAllActivityLogsLoaded(state) {
      state.allActivityLogsLoaded = true;
    },
    setActivityLogsScrollPosition(state, position: number) {
      state.activityLogsScrollPosition = position;
    },
    incrementActivityLogsFetchCounter(state) {
      state.activityLogsFetchCounter++;
    },
    resetActivityLogsModule(state) {
      state.patientActivityLogsMap = {};
      state.allActivityLogsLoaded = false;
      state.activityLogsScrollPosition = null;
      state.activityLogsFetchCounter = 1;
    },
  },
};

export default module;
